<template>
    <div>
        <h1>Page not found</h1>
        <p>It's looking like you may have taken a wrong turn. Don't worry... it happens to the best of us. You might
            want to
            check your internet connection. Here's a little tip that might help you get back on track.</p>
        <button>Ruturn home</button>
    </div>
</template>

<script>
export default {
    name: "NotFound",
}
</script>
