<template>
  <div class="flex  bg-white-100 min-h-screen font-roboto tracking-wide text-mid-gray-500 bg-athens-gray-400">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
}
</script>
